import urls, { urlTemplateParts } from "@/api/config/urls";
import { plainToInstance } from "class-transformer";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import ApiLegalEntityCounterparty from "@/api/types/counterparty/apiLegalEntityCounterparty";
import ApiForeignOrganizationCounterparty from "@/api/types/counterparty/apiForeignOrganizationCounterparty";
import ApiLegalPersonCounterparty from "@/api/types/counterparty/apiLegalPersonCounterparty";
import ApiPhysicalPersonCounterparty from "@/api/types/counterparty/apiPhysicalPersonCounterparty";
import ApiCounterpartyPersistedBase from "@/api/types/counterparty/apiCounterpartyPersistedBase";
import ApiGetCounterpartiesParameters from "@/api/types/counterparty/apiGetCounterpartiesParameters";
import ApiCounterparties from "@/api/types/counterparty/apiCounterparties";
import ApiAccountProfilePersistedBase from "@/api/types/account/apiAccountProfilePersistedBase";
import BaseController from "@/api/shared/baseController";
import ApiCounterpartyEmployeePersistedBase from "@/api/types/counterparty/apiCounterpartyEmployeePersistedBase";
import AbortService from "@/services/abortService";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import ApiCounterpartyEmployeeSnilsPersisted from "@/api/types/counterparty/snils/apiCounterpartyEmployeeSnilsPersisted";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import ApiCounterpartyEmployeeWithTrustInfo from "@/api/types/counterparty/ApiCounterpartyEmployeeWithTrustInfo";
import { i18n } from "@/plugins";

export class CounterpartyController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getCounterpartyEmployees = async (id: string,
		scope = AuthorizationScopeType.GLOBAL): Promise<ApiCounterpartyEmployeeWithTrustInfo[]> => {
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.counterparty.employee.getItems.replace(urlTemplateParts.id, id)
			: urls.counterparty.employee.getOwnItems;

		let { employees } = await this.client.get<{ employees: object[] }>(url);
		return plainToInstance(ApiCounterpartyEmployeeWithTrustInfo, employees);
	};

	getCounterpartyEmployee = async (id: string,
		counterpartyId: string,
		scope = AuthorizationScopeType.GLOBAL): Promise<ApiCounterpartyEmployeePersistedBase> => {
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.counterparty.employee.get
				  .replace(urlTemplateParts.id, counterpartyId)
				  .replace(urlTemplateParts.secondId, id)
			: urls.counterparty.employee.getOwn.replace(urlTemplateParts.secondId, id);

		let { employeePersisted } = await this.client.get<{ employeePersisted: object }>(url);

		return plainToInstance(ApiCounterpartyEmployeePersistedBase, employeePersisted);
	};

	getCounterparties = async (parameters: ApiGetCounterpartiesParameters): Promise<ApiCounterparties> => {
		let data = await this.client.get<{ counterparties: object[], totalCount: number }>(prepareUrl(urls.counterparty.getCounterparties,
			parameters));

		return new ApiCounterparties(plainToInstance(ApiCounterpartyPersistedBase, data.counterparties), data.totalCount);
	};

	getCounterparty = async <T extends ApiCounterpartyPersistedBase>(id: string,
		Type: { new(): T },
		scope = AuthorizationScopeType.GLOBAL): Promise<T> => {

		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.counterparty.getCounterparty.replace(urlTemplateParts.id, id)
			: urls.counterparty.getOwnCounterparty;


		let { counterparty } = await this.client.get<{ counterparty: object }>(url);

		if(counterparty === null)
			throw new HttpNotFoundException(i18n.t("alerts.errors.counterpartyNotFound") as string);

		return plainToInstance(Type, counterparty);
	};

	getCounterpartyProfile = async (id: string): Promise<ApiAccountProfilePersistedBase> => {
		const url = urls.counterparty.getCounterpartyProfile.replace(urlTemplateParts.id, id);

		let { profilePersisted } = await this.client.get<{ profilePersisted: object }>(url);

		return plainToInstance(ApiAccountProfilePersistedBase, profilePersisted);
	};

	getCounterpartyEmployeeProfile = async (counterpartyId: string,
		employeeId: string,
		scope = AuthorizationScopeType.GLOBAL): Promise<ApiAccountProfilePersistedBase> =>
	{
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.counterparty.employee.getEmployeeProfile
				  .replace(urlTemplateParts.id, counterpartyId)
				  .replace(urlTemplateParts.secondId, employeeId)
			: urls.counterparty.employee.getOwnEmployeeProfile
				  .replace(urlTemplateParts.secondId, employeeId);

		let profilePersisted = await this.client.get<{ profilePersisted: object }>(url);

		return plainToInstance(ApiAccountProfilePersistedBase, profilePersisted);
	};

	updateForeignOrganizationCounterparty = async (
		id: string,
		counterparty: ApiForeignOrganizationCounterparty
	): Promise<ApiForeignOrganizationCounterparty> => {
		return await this.client.put(urls.counterparty.foreignOrganization.update.replace(urlTemplateParts.id, id), counterparty);
	};

	createLegalPersonCounterparty = async (counterparty: ApiLegalPersonCounterparty): Promise<string> => {
		return await this.client.post(urls.counterparty.legalPerson.create, counterparty);
	};

	updateLegalPersonCounterparty = async (
		id: string,
		counterparty: ApiLegalPersonCounterparty
	): Promise<ApiLegalPersonCounterparty> => {
		return await this.client.put(urls.counterparty.legalPerson.update.replace(urlTemplateParts.id, id), counterparty);
	};

	createPhysicalPersonCounterparty = async (counterparty: ApiPhysicalPersonCounterparty): Promise<string> => {
		return await this.client.post(urls.counterparty.physicalPerson.create, counterparty);
	};

	updatePhysicalPersonCounterparty = async (id: string,
		counterparty: ApiPhysicalPersonCounterparty): Promise<ApiPhysicalPersonCounterparty> => {
		return await this.client.put(urls.counterparty.physicalPerson.update.replace(urlTemplateParts.id, id), counterparty);
	};

	createLegalEntityCounterparty = async (counterparty: ApiLegalEntityCounterparty): Promise<string> => {
		return await this.client.post(urls.counterparty.legalEntity.create, counterparty);
	};

	updateLegalEntityCounterparty = async (
		id: string,
		counterparty: ApiLegalEntityCounterparty
	): Promise<ApiLegalEntityCounterparty> => {
		return await this.client.put(urls.counterparty.legalEntity.update.replace(urlTemplateParts.id, id), counterparty);
	};

	createCounterpartyEmployeeSnils = async (counterpartyId: string,
		employeeId: string,
		temporaryFileId: string, scope = AuthorizationScopeType.GLOBAL): Promise<{ id: string }> =>
	{
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.counterparty.employee.snils.create
				  .replace(urlTemplateParts.id, counterpartyId)
				  .replace(urlTemplateParts.secondId, employeeId)
			: urls.counterparty.employee.snils.createOwn
				  .replace(urlTemplateParts.secondId, employeeId);

		return await this.client.post(url, { temporaryFileId });
	};

	updateCounterpartyEmployeeSnils = async (counterpartyId: string, employeeId: string,
		temporaryFileId: string, scope = AuthorizationScopeType.GLOBAL): Promise<void> =>
	{
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.counterparty.employee.snils.update
				  .replace(urlTemplateParts.id, counterpartyId)
				  .replace(urlTemplateParts.secondId, employeeId)
			: urls.counterparty.employee.snils.updateOwn
				  .replace(urlTemplateParts.secondId, employeeId);

		return await this.client.put(url, { temporaryFileId });
	};

	getCounterpartyEmployeeSnils = async (counterpartyId: string,
		employeeId: string, scope = AuthorizationScopeType.GLOBAL): Promise<ApiCounterpartyEmployeeSnilsPersisted | null> => {
		try {
			const url = scope === AuthorizationScopeType.GLOBAL
				? urls.counterparty.employee.snils.get
					  .replace(urlTemplateParts.id, counterpartyId)
					  .replace(urlTemplateParts.secondId, employeeId)
				: urls.counterparty.employee.snils.getOwn
					  .replace(urlTemplateParts.secondId, employeeId);

			let result = await this.client.get(url);

			return plainToInstance(ApiCounterpartyEmployeeSnilsPersisted, result);
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};

	activateCounterpartyEmployee = async (counterpartyId: string, employeeId: string, scope = AuthorizationScopeType.GLOBAL) =>
	{
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.counterparty.employee.activate
				  .replace(urlTemplateParts.id, counterpartyId)
				  .replace(urlTemplateParts.secondId, employeeId)
			: urls.counterparty.employee.activateOwn
				  .replace(urlTemplateParts.secondId, employeeId);

		return await this.client.post(url, {});
	};

	deactivateCounterpartyEmployee = async (counterpartyId: string, employeeId: string, scope = AuthorizationScopeType.GLOBAL) => {
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.counterparty.employee.deactivate
				  .replace(urlTemplateParts.id, counterpartyId)
				  .replace(urlTemplateParts.secondId, employeeId)
			: urls.counterparty.employee.deactivateOwn
				  .replace(urlTemplateParts.secondId, employeeId);

		return await this.client.post(url, {});
	};
}
