export default class LegalEntityCounterparty {
	inn: string;
	ogrn: string;
	kpp: string;
	opf: string;
	longName: string;
	shortName: string;
	legalAddress: string;
	isRfrp: boolean;
	isLeasingCompany: boolean;
	isPledgeExpertCompany: boolean;
	isVkmExpertCompany: boolean;
	isFrpAssigneeCompany: boolean;
	registrationDate: number;
	description: string;
	updatedAt: string;

	constructor(
		inn: string = "",
		ogrn: string = "",
		kpp: string = "",
		opf: string = "",
		longName: string = "",
		shortName: string = "",
		legalAddress: string = "",
		updatedAt: string = "",
		registrationDate: number = 0,
		isRfrp: boolean = false,
		isLeasingCompany: boolean = false,
		isPledgeExpertCompany: boolean = false,
		isVkmExpertCompany: boolean = false,
		isFrpAssigneeCompany: boolean = false,
		description: string = "")
	{
		this.inn = inn;
		this.ogrn = ogrn;
		this.kpp = kpp;
		this.opf = opf;
		this.longName = longName;
		this.shortName = shortName;
		this.legalAddress = legalAddress;
		this.description = description;
		this.registrationDate = registrationDate;
		this.updatedAt = updatedAt;
		this.isRfrp = isRfrp;
		this.isLeasingCompany = isLeasingCompany;
		this.isPledgeExpertCompany = isPledgeExpertCompany;
		this.isVkmExpertCompany = isVkmExpertCompany;
		this.isFrpAssigneeCompany = isFrpAssigneeCompany;
	}
}
