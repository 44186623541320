import {
	namespace,
	actionTypes,
	mutationTypes
} from "@/store/modules/counterparty/modules/legalPersonCounterparty/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import LegalPersonCounterpartyState
	from "@/store/modules/counterparty/modules/legalPersonCounterparty/types/legalPersonCounterpartyState";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import LegalPersonCounterparty from "@/store/modules/counterparty/modules/legalPersonCounterparty/types/legalPersonCounterparty";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import { plainToInstance } from "class-transformer";
import router from "@/router";
import ApiLegalPersonCounterparty from "@/api/types/counterparty/apiLegalPersonCounterparty";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import LegalPersonFnsUpdate from "@/store/modules/counterparty/modules/legalPersonCounterparty/types/legalPersonFnsUpdate";
import { validateOgrnip, validatePersonInn } from "@/utils/validator";
import ApiLegalPersonCounterpartyPersisted from "@/api/types/counterparty/apiLegalPersonCounterpartyPersisted";
import { convertIsoToNumber } from "@/utils/dates";
import mapper from "@/store/modules/counterparty/modules/legalPersonCounterparty/mapper";
import { CounterpartyController } from "@/api/counterparty";
import { LegalPersonController } from "@/api/counterparty/legalPerson";
import { FnsController } from "@/api/fns";
import AbortService from "@/services/abortService";
import { RouteNames } from "@/router/routes";
import ApiFnsLegalPerson from "@/api/types/fns/apiFnsLegalPerson";
import { Permissions } from "@/constants/permissions";
import { AuthorizationScopeType } from "@/types/authorization/authorizationScopeType";
import PermissionsService from "@/services/permissionsService";
import { counterpartySnapshotKeys } from "@/store/modules/counterparty/types/counterpartySnapshotKeys";

const abortService = new AbortService();
const fnsController = new FnsController(abortService);
const legalPersonController = new LegalPersonController(abortService);
const counterpartyController = new CounterpartyController(abortService);
const permissionsService = new PermissionsService();

const formMixin = (new FormMixinBuilder()).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["counterparty", "fnsUpdate"]
		}),
		new SnapshotOptions({
			key: counterpartySnapshotKeys.FNS,
			fields: ["fnsUpdate"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new LegalPersonCounterpartyState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<LegalPersonCounterpartyState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters
};

const actions = <ActionTree<LegalPersonCounterpartyState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }, { id }: { id: string | null }) {
		await dispatch(actionTypes.initializeBase);

		if(id) {
			commit(mutationTypes.SET_ID, id);
			await dispatch(actionTypes.fetch);
		}

		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
		commit(mutationTypes.SET_STATE_SNAPSHOT, counterpartySnapshotKeys.FNS);
	},
	async [actionTypes.checkIsRecordUnique]({ dispatch, commit, rootState, state }) {
		if(!validatePersonInn(state.counterparty.inn) || !validateOgrnip(state.counterparty.ogrnIp)) {
			commit(mutationTypes.SET_IS_RECORD_UNIQUE, true);
			return;
		}

		try {
			commit(mutationTypes.SET_IS_RECORD_UNIQUE_CHECK_IN_PROGRESS, true);

			const exists = await legalPersonController.checkLegalPersonIsExists(state.counterparty.inn, state.counterparty.ogrnIp);

			commit(mutationTypes.SET_IS_RECORD_UNIQUE, !exists);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			commit(mutationTypes.SET_IS_RECORD_UNIQUE, true);
		} finally {
			commit(mutationTypes.SET_IS_RECORD_UNIQUE_CHECK_IN_PROGRESS, false);
		}
	},
	async [actionTypes.fetch]({ dispatch, commit, state }) {
		commit(mutationTypes.SET_IS_FORM_LOADING, true);

		try {
			const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ])
				? AuthorizationScopeType.GLOBAL
				: AuthorizationScopeType.OWN;

			let counterpartyPersisted = await counterpartyController.getCounterparty<ApiLegalPersonCounterpartyPersisted>(state.id,
				ApiLegalPersonCounterpartyPersisted, scope);
			let data = counterpartyPersisted;
			let counterparty = {
				...data.counterparty,
				registrationDate: convertIsoToNumber(data.counterparty.registrationDate),
				updatedAt: convertIsoToNumber(data.updatedAt)
			};

			const hasFnsReadPermission = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_FNS_READ]);

			if(hasFnsReadPermission) {

				let apiFnsUpdate = await fnsController.getLegalPersonFnsUpdate(counterparty.inn, counterparty.ogrnIp);

				if(apiFnsUpdate) {
					let fnsUpdate = plainToInstance(LegalPersonFnsUpdate, {
						...apiFnsUpdate,
						isEnabled: true
					});
					commit(mutationTypes.SET_FNS_UPDATE, fnsUpdate);
				} else {
					commit(mutationTypes.SET_IS_FNS_UPDATE_ENABLED, false);
				}
			}

			commit(mutationTypes.SET_COUNTERPARTY, counterparty);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
			commit(mutationTypes.SET_IS_FORM_DISABLED, true);
		} finally {
			commit(mutationTypes.SET_IS_FORM_LOADING, false);
		}
	},
	async [actionTypes.handleInnInput]({ state, commit }) {
		if(!validatePersonInn(state.counterparty.inn))
			return;

		commit(mutationTypes.SET_IS_FORM_READONLY, true);
		commit(mutationTypes.SET_IS_EGRIP_LEGAL_PERSON_LOADING, true);

		try {
			let egripLegalPerson = await fnsController.getEgripLegalPerson(state.counterparty.inn);

			if(egripLegalPerson) {
				let legalPerson = mapper.map(egripLegalPerson, ApiFnsLegalPerson, LegalPersonCounterparty);

				commit(mutationTypes.SET_COUNTERPARTY, legalPerson);
			} else {
				alertService.addCustomError(`Не удалось найти ИП с ИНН ${state.counterparty.inn}`);
				commit(mutationTypes.SET_IS_FNS_UPDATE_ENABLED, false);
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_READONLY, false);
			commit(mutationTypes.SET_IS_EGRIP_LEGAL_PERSON_LOADING, false);
		}
	},
	async [actionTypes.saveFnsUpdate]({ dispatch, commit, state }) {
		let { ogrnIp, inn } = state.counterparty;
		if(state.fnsUpdate.isEnabled) {
			await fnsController.enableLegalPersonFnsUpdate(inn, ogrnIp, state.id);
		} else {
			await fnsController.disableLegalPersonFnsUpdate(inn, ogrnIp, state.id);
		}
	},
	async [actionTypes.save]({ dispatch, commit, getters, state }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			let counterparty = {
				...state.counterparty,
				registrationDate: new Date(state.counterparty.registrationDate).toISOString()
			};

			if(state.id) {
				await counterpartyController.updateLegalPersonCounterparty(
					state.id,
					plainToInstance(ApiLegalPersonCounterparty, counterparty)
				);

				if(getters.stateContainsUnsavedChanges(counterpartySnapshotKeys.FNS))
					await dispatch(actionTypes.saveFnsUpdate);

				commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
				commit(mutationTypes.SET_STATE_SNAPSHOT, counterpartySnapshotKeys.FNS);
				alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);
			} else {
				let id = await counterpartyController.createLegalPersonCounterparty(plainToInstance(ApiLegalPersonCounterparty,
					counterparty));

				commit(mutationTypes.SET_ID, id);

				if(state.fnsUpdate.isEnabled)
					await dispatch(actionTypes.saveFnsUpdate);

				commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
				commit(mutationTypes.SET_STATE_SNAPSHOT, counterpartySnapshotKeys.FNS);

				const hasGlobalPermissionCounterpartyRead = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ]);

				if(!hasGlobalPermissionCounterpartyRead) {
					commit(mutationTypes.SET_IS_COUNTERPARTY_SUCCESS_CREATED_DIALOG_OPENED, true);
				} else {
					alertService.addInfo(AlertKeys.SUCCESS_CREATED_INFO);
					await router.push({ name: RouteNames.COUNTERPARTY, params: { id } });
				}
			}
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.updateViaFns]({ dispatch, commit, state }) {
		commit(mutationTypes.SET_IS_FORM_DISABLED, true);
		commit(mutationTypes.SET_IS_UPDATE_VIA_FNS_OPERATION_EXECUTING, true);

		try {
			let { ogrnIp, inn } = state.counterparty;
			await fnsController.updateLegalPersonViaFns(inn, ogrnIp, state.id);

			// Временно необходима, так как updateLegalEntityViaFns возвращает ответ не дожидаясь обновления данных
			const delay = 1000;
			await new Promise(resolve => setTimeout(resolve, delay));

			await dispatch(actionTypes.fetch);
			commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
			commit(mutationTypes.SET_STATE_SNAPSHOT, counterpartySnapshotKeys.FNS);
			alertService.addInfo(AlertKeys.COUNTERPARTY_SUCCESS_UPDATED_VIA_FNS);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_DISABLED, false);
			commit(mutationTypes.SET_IS_UPDATE_VIA_FNS_OPERATION_EXECUTING, false);
		}
	}
};

const mutations = <MutationTree<LegalPersonCounterpartyState>>{
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	...baseMixin.mutations,
	[mutationTypes.SET_COUNTERPARTY](state, value) {
		state.counterparty = value;
	},
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	},
	[mutationTypes.SET_IS_FNS_UPDATE_ENABLED](state, value) {
		state.fnsUpdate.isEnabled = value;
	},
	[mutationTypes.SET_IS_UPDATE_VIA_FNS_OPERATION_EXECUTING](state, value) {
		state.isUpdateViaFnsOperationExecuting = value;
	},
	[mutationTypes.SET_FNS_UPDATE](state, value) {
		state.fnsUpdate = value;
	},
	[mutationTypes.SET_COUNTERPARTY_INN](state, value) {
		state.counterparty.inn = value;
	},
	[mutationTypes.SET_COUNTERPARTY_OGRNIP](state, value) {
		state.counterparty.ogrnIp = value;
	},
	[mutationTypes.SET_COUNTERPARTY_FULL_NAME](state, value) {
		state.counterparty.fullName = value;
	},
	[mutationTypes.SET_IS_EGRIP_LEGAL_PERSON_LOADING](state, value) {
		state.isEgripLegalPersonLoading = value;
	},
	[mutationTypes.SET_COUNTERPARTY_REGISTRATION_DATE](state, value) {
		state.counterparty.registrationDate = value;
	},
	[mutationTypes.SET_COUNTERPARTY_DESCRIPTION](state, value) {
		state.counterparty.description = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_RFRP](state, value) {
		state.counterparty.isRfrp = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_LEASING_COMPANY](state, value) {
		state.counterparty.isLeasingCompany = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_PLEDGE_EXPERT_COMPANY](state, value) {
		state.counterparty.isPledgeExpertCompany = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_VKM_EXPERT_COMPANY](state, value) {
		state.counterparty.isVkmExpertCompany = value;
	},
	[mutationTypes.SET_COUNTERPARTY_IS_FRP_ASSIGNEE_COMPANY](state, value) {
		state.counterparty.isFrpAssigneeCompany = value;
	},
	[mutationTypes.SET_IS_COUNTERPARTY_SUCCESS_CREATED_DIALOG_OPENED](state, value) {
		state.isCounterpartySuccessCreatedDialogOpened = value;
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch } = store;
	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case  resolveMutation(storeManager.counterparty.legalPerson.namespace, mutationTypes.SET_COUNTERPARTY_INN):
			{
				let legalPersonState = resolveNestedState<LegalPersonCounterpartyState>(state,
					storeManager.counterparty.legalPerson.namespace);

				if(!legalPersonState.id) {
					await dispatch(resolveAction(storeManager.counterparty.legalPerson.namespace, actionTypes.handleInnInput));
					await dispatch(resolveAction(storeManager.counterparty.legalPerson.namespace, actionTypes.checkIsRecordUnique));
				}

				break;
			}

			default:
				break;
		}
	});
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const legalPersonCounterpartyModule = {
	namespace, state, getters, actions, mutations, subscribe, namespaced: true
};

export default legalPersonCounterpartyModule;
