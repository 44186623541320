import { createMap, createMapper, MappingProfile, forMember, mapFrom, addProfile, fromValue, ignore } from "@automapper/core";
import { classes } from "@automapper/classes";
import LegalEntityCounterparty from "@/store/modules/counterparty/modules/legalEntityCounterparty/types/legalEntityCounterparty";
import { convertIsoToNumber } from "@/utils/dates";
import ApiLegalEntityCounterpartyPersisted from "@/api/types/counterparty/apiLegalEntityCounterpartyPersisted";
import ApiLegalEntityCounterpartyHead from "@/api/types/counterparty/apiLegalEntityCounterpartyHead";
import ApiLegalEntityCounterparty from "@/api/types/counterparty/apiLegalEntityCounterparty";
import ApiLegalEntityIdentifiers from "@/api/types/legalEntity/apiLegalEntityIdentifiers";
import ApiLegalEntity from "@/api/types/legalEntity/apiLegalEntity";
import { CounterpartyType } from "@/types/CounterpartyType";
import LegalEntityCounterpartyHead
	from "@/store/modules/counterparty/modules/legalEntityCounterparty/modules/legalEntityCounterpartyHead/types/legalEntityCounterpartyHead";

const mapper = createMapper({
	strategyInitializer: classes()
});

const legalEntityProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiLegalEntity, LegalEntityCounterparty,
		forMember(d => d.inn, mapFrom(x => x.inn)),
		forMember(d => d.ogrn, mapFrom(x => x.ogrn)),
		forMember(d => d.kpp, mapFrom(x => x.kpp)),
		forMember(d => d.description, ignore()),
		forMember(d => d.opf, mapFrom(x => x.opf)),
		forMember(d => d.longName, mapFrom(x => x.longName)),
		forMember(d => d.shortName, mapFrom(x => x.shortName)),
		forMember(d => d.legalAddress, mapFrom(x => x.legalAddress)),
		forMember(d => d.isRfrp, fromValue(false)),
		forMember(d => d.isLeasingCompany, fromValue(false)),
		forMember(d => d.isPledgeExpertCompany, fromValue(false)),
		forMember(d => d.isVkmExpertCompany, fromValue(false)),
		forMember(d => d.isFrpAssigneeCompany, fromValue(false)),
		forMember(d => d.registrationDate, mapFrom(x => convertIsoToNumber(x.registrationDate)))
	);

	createMap(mapper, ApiLegalEntityCounterpartyPersisted, LegalEntityCounterparty,
		forMember(d => d.inn, mapFrom(x => x.counterparty.inn)),
		forMember(d => d.updatedAt, mapFrom(x => convertIsoToNumber(x.updatedAt))),
		forMember(d => d.ogrn, mapFrom(x => x.counterparty.ogrn)),
		forMember(d => d.kpp, mapFrom(x => x.counterparty.kpp)),
		forMember(d => d.description, mapFrom(x => x.counterparty.description)),
		forMember(d => d.opf, mapFrom(x => x.counterparty.opf)),
		forMember(d => d.longName, mapFrom(x => x.counterparty.longName)),
		forMember(d => d.shortName, mapFrom(x => x.counterparty.shortName)),
		forMember(d => d.legalAddress, mapFrom(x => x.counterparty.legalAddress)),
		forMember(d => d.isRfrp, mapFrom(x => x.counterparty.isRfrp)),
		forMember(d => d.isLeasingCompany, mapFrom(x => x.counterparty.isLeasingCompany)),
		forMember(d => d.isPledgeExpertCompany, mapFrom(x => x.counterparty.isPledgeExpertCompany)),
		forMember(d => d.isVkmExpertCompany, mapFrom(x => x.counterparty.isVkmExpertCompany)),
		forMember(d => d.isFrpAssigneeCompany, mapFrom(x => x.counterparty.isFrpAssigneeCompany)),
		forMember(d => d.registrationDate, mapFrom(x => convertIsoToNumber(x.counterparty.registrationDate)))
	);

	createMap(mapper, LegalEntityCounterparty, ApiLegalEntityIdentifiers,
		forMember(d => d.inn, mapFrom(x => x.inn)),
		forMember(d => d.ogrn, mapFrom(x => x.ogrn)),
		forMember(d => d.kpp, mapFrom(x => x.kpp))
	);

	createMap(mapper, LegalEntityCounterparty, ApiLegalEntityCounterparty,
		forMember(d => d.type, fromValue(CounterpartyType.LEGAL_ENTITY)),
		forMember(d => d.inn, mapFrom(x => x.inn)),
		forMember(d => d.description, mapFrom(x => x.description)),
		forMember(d => d.ogrn, mapFrom(x => x.ogrn)),
		forMember(d => d.kpp, mapFrom(x => x.kpp)),
		forMember(d => d.opf, mapFrom(x => x.opf)),
		forMember(d => d.longName, mapFrom(x => x.longName)),
		forMember(d => d.shortName, mapFrom(x => x.shortName)),
		forMember(d => d.legalAddress, mapFrom(x => x.legalAddress)),
		forMember(d => d.registrationDate, mapFrom(x => new Date(x.registrationDate).toISOString())),
		forMember(d => d.isRfrp, mapFrom(x => x.isRfrp)),
		forMember(d => d.isLeasingCompany, mapFrom(x => x.isLeasingCompany)),
		forMember(d => d.heads, fromValue([])),
		forMember(d => d.isPledgeExpertCompany, mapFrom(x => x.isPledgeExpertCompany)),
		forMember(d => d.isVkmExpertCompany, mapFrom(x => x.isVkmExpertCompany)),
		forMember(d => d.isFrpAssigneeCompany, mapFrom(x => x.isFrpAssigneeCompany))
	);

	createMap(mapper, LegalEntityCounterpartyHead, ApiLegalEntityCounterpartyHead,
		forMember(d => d.inn, mapFrom(x => x.inn)),
		forMember(d => d.snils, mapFrom(x => x.snils)),
		forMember(d => d.fullName, mapFrom(x => x.fullName)),
		forMember(d => d.position, mapFrom(x => x.position)),
		forMember(d => d.description, mapFrom(x => x.description))
	);
};

addProfile(mapper, legalEntityProfile);

export default mapper;
