<template>
	<v-form v-if="isInitialized" :readonly="isFormSaving || isFormReadonly" :disabled="isFormDisabled" :ref="refs.form"
			v-model.trim="formValid">
		<frp-form-card>
			<frp-form-card-blocks v-if="can(Permissions.GLOBAL_COUNTERPARTY_FNS_READ)">
				<frp-form-card-block :title="$t('titles.update')">
					<frp-form-card-block-row style="min-height: 68px">
						<frp-form-card-block-col cols="12" class="d-flex flex-wrap pt-4 mt-2">
							<frp-switch :label="$t('fields.switch.enableFnsUpdate.label')"
										class="mt-0 pt-0 mr-5"
										:disabled="!can(Permissions.GLOBAL_COUNTERPARTY_FNS_UPDATE)"
										v-model="isFnsUpdateEnabled">
							</frp-switch>

							<v-fade-transition v-if="isCreateMode">
								<frp-alert class="mb-md-0 ml-md-8 mt-2 mt-md-0"
										   v-if="isFnsUpdateEnabled && !isInnValid"
										   :text="$t('alerts.errors.requiredInnForLegalPerson')">
								</frp-alert>
							</v-fade-transition>
							<div v-else-if="fnsUpdate.isEnabled"
								 class="d-flex ml-md-15 mt-2 mb-1 mt-md-0">
								<div class="d-flex flex-column justify-center">
									<frp-text-caption class="mr-3">{{ $t("content.lastUpdateTime") }}</frp-text-caption>
									<frp-text-caption class="mr-3 mt-2">{{ $t("content.lastVerificationTime") }}</frp-text-caption>
								</div>
								<div class="d-flex flex-column ml-4">
									<frp-text-body-two class="">{{ fnsLastUpdated || $t("content.generalNotUpdated") }}</frp-text-body-two>
									<frp-text-body-two class=" mt-2">{{ fnsLastChecked || $t("content.generalNotUpdated") }}</frp-text-body-two>
								</div>
							</div>
						</frp-form-card-block-col>
						<frp-form-card-block-col v-if="!isCreateMode && can(Permissions.GLOBAL_COUNTERPARTY_FNS_FORCE_UPDATE)" cols="12"
												 class="d-flex align-center flex-wrap pt-4">
							<frp-btn
								class="mt-0 pt-0 mb-2"
								:loading="isUpdateViaFnsOperationExecuting"
								:disabled="!hasEditPermissions"
								@click="updateViaFns">
								{{ $t("buttons.updateViaFns") }}
							</frp-btn>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-block>
			</frp-form-card-blocks>

			<frp-form-card-blocks>
				<frp-form-card-block :title="$t('titles.general')">
					<frp-form-card-block-row>
						<frp-form-card-block-col cols="12" sm="6" md="4" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
							<frp-form-item>
								<frp-text-field :label="$t('fields.inn.label')"
												name="inn"
												autocomplete="inn"
												:errors="innErrors"
												required
												:mask="PERSON_INN_FORMAT"
												:disabled="!hasEditPermissions || !isCreateMode "
												:loading="isEgripLegalPersonLoading"
												v-model="inn"
												:rules="validation.inn"
												:placeholder="$t('fields.inn.placeholder')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-text-field :label="$t('fields.ogrnip.label')"
												name="ogrn"
												autocomplete="ogrn"
												:disabled="!hasEditPermissions || isFnsUpdateEnabled"
												required
												:mask="OGRNIP_FORMAT"
												v-model="ogrnip"
												:rules="validation.ogrnip"
												:placeholder="$t('fields.ogrnip.placeholder')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-textarea :label="$t('fields.description.label')"
											  autocomplete="off"
											  :disabled="!hasEditPermissions || isFnsUpdateEnabled"
											  :rules="validation.description"
											  :placeholder="$t('fields.description.placeholderInput')"
											  v-model="description"
											  outlined>
								</frp-textarea>
							</frp-form-item>
						</frp-form-card-block-col>

						<frp-form-card-block-col offset-md="2" cols="12" sm="6" md="4" :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
							<frp-form-item>
								<frp-text-field :label="$t('fields.personFullName.label')"
												name="name"
												autocomplete="name"
												:disabled="!hasEditPermissions || isFnsUpdateEnabled"
												required
												v-model="fullName"
												:rules="validation.fullName"
												:placeholder="$t('fields.personFullName.placeholder')">
								</frp-text-field>
							</frp-form-item>
							<frp-form-item>
								<frp-date-field
									:label="$t('fields.legalPersonRegisterDate.label')"
									:disabled="!hasEditPermissions || isFnsUpdateEnabled"
									:rules="validation.registrationDate"
									v-model="registrationDate"
									:max="Date.now()"
									:placeholder="$t('fields.legalPersonRegisterDate.placeholder')"/>
							</frp-form-item>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-block>
			</frp-form-card-blocks>

			<frp-form-card-blocks v-if="!isCounterpartyEmployee">
				<frp-form-card-block :title="$t('titles.characteristics')">
					<frp-form-card-block-row>
						<frp-form-card-block-col cols="12" sm="6" md="4" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
							<frp-form-item>
								<frp-switch :label="$t('fields.switch.frp.label')"
											class="mb-6"
											:disabled="!hasEditPermissions"
											v-model="isRfrp">
								</frp-switch>
							</frp-form-item>
							<frp-form-item>
								<frp-switch :label="$t('fields.switch.leasingCompany.label')"
											class="mb-6"
											:disabled="!hasEditPermissions"
											v-model="isLeasingCompany">
								</frp-switch>
							</frp-form-item>
							<frp-form-item>
								<frp-switch :label="$t('fields.switch.collateralExpertOrganisation.label')"
											class="mb-6"
											:disabled="!hasEditPermissions"
											v-model="isPledgeExpertCompany">
								</frp-switch>
							</frp-form-item>
							<frp-form-item>
								<frp-switch :label="$t('fields.switch.vkmExpertCompany.label')"
											class="mb-6"
											v-model="isVkmExpertCompany"
											:disabled="!hasEditPermissions">
								</frp-switch>
							</frp-form-item>
							<frp-form-item>
								<frp-switch :label="$t('fields.switch.frpAssigneeCompany.label')"
											class="mb-6"
											v-model="isFrpAssigneeCompany"
											:disabled="!hasEditPermissions">
								</frp-switch>
							</frp-form-item>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-block>
			</frp-form-card-blocks>
			<frp-form-actions :is-save-disabled="!stateContainsUnsavedChanges || !isFormValid"
							  v-if="hasEditPermissions"
							  :is-cancel-disabled="!stateContainsUnsavedChanges"
							  :is-loading-state="isFormSaving || isFormLoadingState"
							  @cancel="onFormCancel"
							  @submit="submit">
			</frp-form-actions>
			<frp-confirm-dialog v-if="isUnsavedChangesDialogEnabled"
								@submit="handleUpdateViaFnsWithUnsavedChanges"
								@cancel="isUnsavedChangesDialogEnabled = false"
								:title="$t('dialogs.confirmOperationWithUnsavedChanges.title')"
								:description="$t('dialogs.confirmOperationWithUnsavedChanges.description')">
			</frp-confirm-dialog>
		</frp-form-card>

		<frp-dialog v-model="isCounterpartySuccessCreatedDialogOpened" persistent>
			<template #content>
				<v-row>
					<v-col align="center">
						<v-img width="122" height="122" :src="successCreatedImgSrc" alt="success created"></v-img>
					</v-col>
				</v-row>
				<v-row>
					<v-col align="center">
						<span class="text-h6 primary--text text--darken-1 mb-5">{{ $t("alerts.info.organisationWasCreated") }}</span>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="d-flex justify-center">
						<frp-message-box icon="ico_info">{{ $t("alerts.info.youCanCloseCurrentBrowserPage") }}</frp-message-box>
					</v-col>
				</v-row>
			</template>
		</frp-dialog>
	</v-form>
	<frp-legal-person-general-loader v-else></frp-legal-person-general-loader>
</template>

<script>
import FrpMessageBox from "@/components/common/FrpMessageBox.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import { getterTypes as userGetterTypes } from "@/store/modules/user/types";
import FrpAlert from "Components/alerts/FrpAlert";
import FrpCountryAutocomplete from "Components/fields/FrpCountryAutocomplete";
import FrpRadioCardGroup from "Components/fields/FrpRadioCardGroup";
import FrpTextField from "Components/fields/FrpTextField";
import FrpNestedContentLayout from "Components/layouts/FrpNestedContentLayout";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpTextBodyTwo from "Components/typography/FrpTextBodyTwo";
import FrpTextCaption from "Components/typography/FrpTextCaption";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpFormActions from "Components/form/FrpFormActions";
import FrpDateField from "Components/fields/FrpDateField";
import FrpTextarea from "Components/fields/FrpTextarea";
import FrpSwitch from "Components/fields/FrpSwitch";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpConfirmDialog from "Components/dialogs/FrpConfirmDialog";
import { Permissions } from "Constants/permissions";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import formMixin from "Mixins/formMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { RouteNames } from "Router/routes";
import storeManager from "Store/manager";
import { actionTypes, mutationTypes } from "Store/modules/counterparty/modules/legalPersonCounterparty/types";
import { formatDate } from "Utils/dates";
import { dateFormat, dateTimeFormat, OGRNIP_FORMAT, PERSON_INN_FORMAT } from "Utils/formats";
import {
	preparePersonMaskedInnValidationRule,
	prepareMaxLengthRule,
	requiredRule, prepareMaskedOgrnValidationRule, prepareMaskedOgrnipValidationRule, prepareFullNameRule
} from "Utils/validation";
import { validatePersonInn } from "Utils/validator";
import FrpLegalPersonGeneralLoader from "Views/counterparty/sections/FrpLegalPersonGeneralLoader";
import { createNamespacedHelpers } from "vuex";
import { maxLength } from "Constants/validation";

const namespace = storeManager.counterparty.legalPerson.namespace;
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const userHelpers = createNamespacedHelpers(storeManager.user.namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, formMixin, authorizationMixin],
	name: "frp-legal-person-general",
	data() {
		return {
			namespace,
			RouteNames,
			PERSON_INN_FORMAT,
			OGRNIP_FORMAT,
			isUnsavedChangesDialogEnabled: false,
			validation: {
				fullName: [prepareMaxLengthRule({ maxLength: maxLength.short })],
				inn: [preparePersonMaskedInnValidationRule()],
				ogrnip: [prepareMaskedOgrnipValidationRule()],
				registrationDate: [],
				description: [prepareMaxLengthRule({ maxLength: maxLength.long })]
			},
			successCreatedImgSrc: `${process.env.VUE_APP_BASE_URL}/img/images/ico_success.png`
		};
	},
	computed: {
		...mapState({
			isUpdateViaFnsOperationExecuting: state => state.isUpdateViaFnsOperationExecuting,
			counterparty: state => state.counterparty,
			fnsUpdate: state => state.fnsUpdate,
			isInitialized: state => state.isInitialized,
			isEgripLegalPersonLoading: state => state.isEgripLegalPersonLoading,
			isCounterpartySuccessCreatedDialogOpened: state => state.isCounterpartySuccessCreatedDialogOpened
		}),
		...userHelpers.mapGetters({
			isCounterpartyEmployee: userGetterTypes.isCounterpartyEmployee
		}),
		hasEditPermissions() {
			return this.isCreateMode ? this.can(Permissions.GLOBAL_COUNTERPARTY_CREATE) : this.can(Permissions.GLOBAL_COUNTERPARTY_UPDATE);
		},
		innErrors() {
			return this.isRecordUnique ? [] : [`${this.$t("alerts.errors.legalPersonAlreadyExists")}`];
		},
		isFnsUpdateEnabled: {
			get() {
				return this.fnsUpdate.isEnabled;
			},
			set(value) {
				this.setIsFnsUpdateEnabled(value);
			}
		},
		fnsLastUpdated() {
			return this.fnsUpdate && formatDate(this.counterparty.updatedAt, dateTimeFormat);
		},
		isInnValid() {
			return validatePersonInn(this.inn);
		},
		inn: {
			get() {
				return this.counterparty.inn;
			},
			set(value) {
				if(value !== this.inn)
					this.setInn(value);
			}
		},
		ogrnip: {
			get() {
				return this.counterparty.ogrnIp;
			},
			set(value) {
				this.setOgrnIp(value);
			}
		},
		fullName: {
			get() {
				return this.counterparty.fullName;
			},
			set(value) {
				this.setFullName(value);
			}
		},
		registrationDate: {
			get() {
				return this.counterparty.registrationDate;
			},
			set(value) {
				this.setRegistrationDate(value && value.getTime());
			}
		},
		description: {
			get() {
				return this.counterparty.description;
			},
			set(value) {
				this.setDescription(value);
			}
		},
		isRfrp: {
			get() {
				return this.counterparty.isRfrp;
			},
			set(value) {
				this.setIsRfrp(value);
			}
		},
		isLeasingCompany: {
			get() {
				return this.counterparty.isLeasingCompany;
			},
			set(value) {
				this.setIsLeasingCompany(value);
			}
		},
		isPledgeExpertCompany: {
			get() {
				return this.counterparty.isPledgeExpertCompany;
			},
			set(value) {
				this.setIsPledgeExpertCompany(value);
			}
		},
		isVkmExpertCompany: {
			get() {
				return this.counterparty.isVkmExpertCompany;
			},
			set(value) {
				this.setIsVkmExpertCompany(value);
			}
		},
		isFrpAssigneeCompany: {
			get() {
				return this.counterparty.isFrpAssigneeCompany;
			},
			set(value) {
				this.setIsFrpAssigneeCompany(value);
			}
		}
	},
	methods: {
		...mapActions({
			save: actionTypes.save,
			updateViaFnsInternal: actionTypes.updateViaFns
		}),
		...mapMutations({
			setInn: mutationTypes.SET_COUNTERPARTY_INN,
			setIsFnsUpdateEnabled: mutationTypes.SET_IS_FNS_UPDATE_ENABLED,
			setOgrnIp: mutationTypes.SET_COUNTERPARTY_OGRNIP,
			setFullName: mutationTypes.SET_COUNTERPARTY_FULL_NAME,
			setIsRfrp: mutationTypes.SET_COUNTERPARTY_IS_RFRP,
			setIsLeasingCompany: mutationTypes.SET_COUNTERPARTY_IS_LEASING_COMPANY,
			setIsPledgeExpertCompany: mutationTypes.SET_COUNTERPARTY_IS_PLEDGE_EXPERT_COMPANY,
			setIsVkmExpertCompany: mutationTypes.SET_COUNTERPARTY_IS_VKM_EXPERT_COMPANY,
			setIsFrpAssigneeCompany: mutationTypes.SET_COUNTERPARTY_IS_FRP_ASSIGNEE_COMPANY,
			setRegistrationDate: mutationTypes.SET_COUNTERPARTY_REGISTRATION_DATE,
			setDescription: mutationTypes.SET_COUNTERPARTY_DESCRIPTION
		}),
		updateViaFns() {
			if(this.stateContainsUnsavedChanges) {
				this.isUnsavedChangesDialogEnabled = true;
			} else {
				this.updateViaFnsInternal();
			}
		},
		async handleUpdateViaFnsWithUnsavedChanges() {
			this.isUnsavedChangesDialogEnabled = false;
			await this.updateViaFnsInternal();
		}
	},
	async created() {
		await this.initializeStore({ id: this.$route.params.id });
	},
	components: {
		FrpMessageBox,
		FrpDialog,
		FrpAlert,
		FrpTextBodyTwo,
		FrpFormActions,
		FrpCountryAutocomplete,
		FrpRadioCardGroup,
		FrpTextField,
		FrpFormItem,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpNestedContentLayout,
		FrpBottomSpace,
		FrpTextCaption,
		FrpLegalPersonGeneralLoader,
		FrpDateField,
		FrpTextarea,
		FrpSwitch,
		FrpBtn,
		FrpConfirmDialog
	}
};
</script>

<style scoped>

</style>
